import { createLazyFileRoute } from "@tanstack/react-router";
import { IpAddressView } from "../../../../../../modules/network/ipAddress/IpAddressView.tsx";

export const Route = createLazyFileRoute("/_main/network/ip_address/$location/$uuid/")({
    component: Component,
});

function Component() {
    const { uuid, location } = Route.useParams();
    // NB: `key` prop ensures that the component state is reset when the route changes (to prevent eg. `isOpen` etc. states from remaining the same)
    return <IpAddressView key={uuid} location={location} addressId={uuid} />;
}
